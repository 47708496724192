import { Fragment, useEffect } from "react";
import { IPost, IPostResponse } from "../../models/post.interface";
import { Products } from "../../components/products/Products";
import { Search } from "../../components/search/Search";
import { useDispatch, useSelector } from "react-redux";
import { postActions } from '../../store/posts-slice';
import { RootState } from '../../store';
import { useHttp } from "../../hooks/use-http";
import styles from "./Home.module.scss";
import { useLocation } from "react-router";
import { FbPagePlugin } from "../../components/fb-page-plugin/FbPagePlugin";
import { InstallPrompt } from "../../components/install-prompt/InstallPrompt";

export function Home() {
    const dispatch = useDispatch();
    const location = useLocation();
    const items: IPost[] = useSelector<RootState, IPost[]>(state => state.posts.filteredItems);
    const updatedAt: number = useSelector<RootState, number>(state => state.posts.updatedAt);
    const keyword: string = useSelector<RootState, string>(state => state.posts.keyword);
    const selectedCategories: string[] = useSelector<RootState, string[]>(state => state.posts.selectedCategories);
    const categories: string[] = useSelector<RootState, string[]>(state => state.posts.categories);
    const { request, error, loading } = useHttp<IPostResponse>();

    useEffect(() => {
        dispatch(postActions.selectGroup(location.pathname));
    }, [location.pathname, dispatch]);

    useEffect(() => {
        (async () => {
            const offlineDataKey: string = 'offline-data';
            try {
                const response: IPostResponse | null = await request(`data.json?v=${Date.now()}`);
                const latestData: IPost[] = response?.data || [];
                if (latestData.length > 0) {
                    dispatch(postActions.update(latestData));
                    localStorage[offlineDataKey] = JSON.stringify(latestData);
                    return;
                }
                throw new Error('Unable to fetch data');
            }
            catch {
                const savedData:any = localStorage[offlineDataKey];
                if (!!savedData) {
                    dispatch(postActions.update(JSON.parse(savedData)));
                }
            }
        })();
    }, [request, dispatch, updatedAt]);

    function searchHandler(searchKey: string) {
        if (searchKey === keyword) {
            return;
        }
        if (searchKey?.length < 3) {
            dispatch(postActions.setKeyword(''));
            return;
        }
        dispatch(postActions.setKeyword(searchKey));
    }

    function toggleCategory(category: string) {
        dispatch(postActions.toggleCategory(category));
    }

    function getCategoryClassName(category: string): string {
        let categoryClassName: string = "btn bg-white btn-outline-info";
        if (selectedCategories.indexOf(category) > -1) categoryClassName += ` ${styles.enabled}`;
        return categoryClassName
    }
    const canShowTags: boolean = categories.length <= 12;
    const isMobileMode: boolean = document.body.clientWidth <= 768;
    const fbPageBannerWidth: number = isMobileMode ? 320 : 500;

    return <Fragment>
        <div className="mb-3">
            <Search searchHandler={searchHandler.bind(this)}></Search>
            <div className={`${styles.buttonGroup} my-2`}>
                {canShowTags && categories.map(cat => <button key={cat} type="button"
                    onClick={toggleCategory.bind(this, cat)} className={getCategoryClassName(cat)}>{cat}</button>)}
            </div>
            {error && <p className="text-danger text-center">{error}</p>}
            {loading
                ? <p className="text-center">იტვირთება...</p>
                : <Products className="mb-3" items={items}></Products>}
        </div>
        <InstallPrompt isMobile={isMobileMode}></InstallPrompt>
        {<div className="d-flex justify-content-center">
            <FbPagePlugin bannerWidth={fbPageBannerWidth}></FbPagePlugin>
        </div>}
    </Fragment>
}