import { useEffect, useState } from "react"

export function InstallPrompt(props: { isMobile: boolean }) {

    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [showAlert, setShowAlert] = useState(true);
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', function (e) {
            e.preventDefault();
            setDeferredPrompt(e);
        });
    }, []);

    const showInstallOption = function () {
        deferredPrompt.prompt();
    };

    const hideAlert = function () {
        setShowAlert(false);
    }

    return props.isMobile && showAlert && !!deferredPrompt
        ? <div className="bottom-fixed d-flex justify-content-center text-center">
            <div className="alert alert-info alert-dismissible fade show" role="alert">
                გადმოწერე აპლიკაცია და კიდევ უფრო მარტივად ადევნე თვალი ახალ შეთავაზებებს.&nbsp;&nbsp;&nbsp;
                <button style={{ color: '#0dcaf0' }} onClick={showInstallOption}
                    type="button" className="btn bg-white btn-outline-info">გადმოწერა</button>
                <span onClick={hideAlert} className="close" data-dismiss="alert" aria-label="close">&times;</span>
            </div>
        </div>
        :
        <div></div>
}