import React, { Fragment, useState } from 'react';
import './App.scss';
import { Home } from './pages/home/Home';
import { Switch, Route, NavLink } from 'react-router-dom';
import { IMenuItem } from './models/post.interface';
import { useSelector } from 'react-redux';
import { RootState } from './store';

function App() {
  const menu: IMenuItem[] = useSelector<RootState, IMenuItem[]>(state => state.posts.groups);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a href="/" className="navbar-brand">
            <img src="/logo.png" alt="" width="45" height="40" />
          </a>
          <button className="navbar-toggler" type="button" onClick={() => setCollapsed(!collapsed)}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`${collapsed ? 'collapse ' : ''} navbar-collapse flex-row-reverse`} id="navbar">
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              {menu.map(item => <li key={item.title} className="nav-item">
                <NavLink onClick={() => setCollapsed(!collapsed)} activeClassName="active"
                  className="nav-link" to={item.path}>{item.title}</NavLink>
              </li>)}
              <li className="nav-item fb-share-button-container">
                <div className="fb-like nav-link" data-href="https://www.facebook.com/110637108047631"
                  data-layout="button" data-width="210" data-action="like" data-size="large" data-share="true"></div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="top-banner">
        <a href="https://hotelpro.ge?utm_source=salespot.ge" rel="noopener noreferrer" target="_blank">
          <img src="https://salespot.ge/top-banner.jpg" alt="hotelpro" />
        </a>
      </div>
      <div className="container">
        <Switch>
          <Route path="*">
            <Home></Home>
          </Route>
        </Switch>
      </div>
    </Fragment>
  );
}

export default App;
