import { IPost } from '../../models/post.interface';
import { ProductItem } from '../product-item/ProductItem';
import styles from './Products.module.scss';

export function Products(props: { items: IPost[], className: string }) {
    return <div className={styles.products}>
        <div className="row">
            {props.items.length > 0 
            ? props.items.map((item: IPost) => <ProductItem key={item.id} item={item}></ProductItem>)
            : <p className="mt-2 text-center">ჩანაწერები არ მოიძებნა</p>}
        </div>
    </div>
}