import { useEffect } from "react";

export function FbPagePlugin(props: { bannerWidth: number }) {

    useEffect(() => {
        document.addEventListener('fb_init', e => window['FB'].XFBML.parse());
    }, []);

    return <div className="fb-page" data-href="https://www.facebook.com/110637108047631"
        data-tabs="timeline" data-width={props.bannerWidth}
        data-height="70" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false"
        data-show-facepile="false">
        <blockquote cite="https://www.facebook.com/110637108047631" className="fb-xfbml-parse-ignore">
            <a href="https://www.facebook.com/110637108047631">salespot.ge - ფასდაკლებები და შეთავაზებები</a>
        </blockquote>
    </div>
}