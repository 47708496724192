import { useCallback, useState } from "react";

export function useHttp<TData>() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const request = useCallback(async (url: string, method: string = 'GET', body?: any) => {
        try {
            setLoading(true);
            setError(undefined);
            const response = await fetch(url, {
                method: method,
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setLoading(false);
            if (response.ok) {
                return await response.json() as TData;
            }
            return null;
        }
        catch (e) {
            const errorMessage: string = `დაფიქსირდა შეცდომა. გთხოვთ სცადოთ მოგვიანებით.`;
            setLoading(false);
            setError(errorMessage);
            return null;
        }
        finally {
            
        }
    }, []);

    return {
        request,
        loading,
        error,
    }
}