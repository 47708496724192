import { createSlice } from "@reduxjs/toolkit";
import { IMenuItem, IPost } from '../models/post.interface';

type IPostsState = {
    items: IPost[],
    keyword: string,
    filteredItems: IPost[],
    selectedCategories: string[],
    categories: string[],
    allCategories: string[],
    updatedAt: number,
    groups: IMenuItem[],
    path?: string
}

const initialState: IPostsState = {
    items: new Array<IPost>(),
    keyword: '',
    filteredItems: new Array<IPost>(),
    selectedCategories: [],
    allCategories: [],
    categories: [],
    updatedAt: 0,
    groups: [],
    path: undefined
};

const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        updatedAt(state, action: { type: string, payload: number }) {
            state.updatedAt = action.payload;
        },
        update(state, action: { type: string, payload: IPost[] }) {
            state.items = action.payload;
            state.filteredItems = action.payload;
            state.selectedCategories = [];
            state.keyword = '';
            state.groups = Array.from(new Set(action.payload.map(item => `${item.groupName}=${item.path}`)))
                .map(item => ({ title: item.split('=')[0], path: item.split('=')[1] }));
            state.filteredItems = state.filteredItems
            .filter(s => (state.path?.length || 0) < 2 || s.path === state.path);
            state.categories = Array.from(new Set(state.filteredItems.map(item => item.category)));
        },
        setKeyword(state, action: { type: string, payload: string }) {
            state.keyword = action.payload;
            state.filteredItems = !action.payload ? state.items : state.items.filter((item: IPost) =>
                item.message.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
            state.filteredItems = state.selectedCategories.length > 0
                ? state.filteredItems.filter(item => state.selectedCategories.indexOf(item.category) > -1)
                : state.filteredItems;
            state.filteredItems = !!state.path
                ? state.filteredItems.filter(s => s.path === state.path)
                : state.filteredItems;
        },
        toggleCategory(state, action: { type: string, payload: string }) {
            state.selectedCategories = state.selectedCategories.indexOf(action.payload) > -1
                ? state.selectedCategories.filter(item => item !== action.payload)
                : [action.payload, ...state.selectedCategories];
            state.filteredItems = !state.keyword ? state.items : state.items.filter((item: IPost) =>
                item.message.toLowerCase().indexOf(state.keyword.toLowerCase()) > -1);
            state.filteredItems = state.selectedCategories.length > 0
                ? state.filteredItems.filter(item => state.selectedCategories.indexOf(item.category) > -1)
                : state.filteredItems;
            state.filteredItems = !!state.path
                ? state.filteredItems.filter(s => s.path === state.path)
                : state.filteredItems;
        },
        selectGroup(state, action: { type: string, payload: string }) {
            state.path = action.payload?.length < 2 ? undefined : action.payload;
            state.categories = Array.from(new Set(state.items
                .filter(item => item.path === action.payload)
                .map(item => item.category)));
            state.selectedCategories = [];
            state.filteredItems = state.items.filter(s => s.path === state.path);
            state.keyword = '';
        }
    },
});

export const postActions = postsSlice.actions;
export default postsSlice.reducer;