import { useState } from "react";
import linkifyHtml from 'linkify-html';
import { IPost } from "../../models/post.interface";
import styles from "./ProductItem.module.scss";

export function ProductItem(props: { item: IPost }) {
    const [pageId, postId] = (props.item.parent_id || props.item.id).split('_');
    const [showMore, setShowMore] = useState(false);
    const url: string = `https://www.facebook.com/${pageId}/posts/${postId}`;
    const length: number = 250;

    const handleShowMoreClick = () => {
        setShowMore(true);
        return false;
    }

    const canShowMoreButton: boolean = props.item.message.length > length && !showMore;
    const shortText: string = props.item.message.padEnd(length, ' ').substring(0, length) + (canShowMoreButton ? '...' : '');
    const linkifiedText: string = linkifyHtml(
        props.item.message,
        { truncate: 60, formatHref: (value, _) => `${value}?utm_source=salespot.ge` }
    );

    return <div className={`card col-12 col-md-6 col-lg-4 mt-2 ${styles.item}`}>
        <img data-id={props.item.id} src={props.item.photoUrl} loading="lazy" className="card-img-top mt-2" alt="" />
        <h5 className="card-title mt-3 mx-2"><strong>{props.item.category}</strong></h5>
        <div className={`card-body ${styles.cardBody}`}>
            <span dangerouslySetInnerHTML={{ __html: canShowMoreButton ? shortText : linkifiedText }}></span>
            <div className={`${styles.showMoreWrapper} d-flex align-items-center justify-content-between`}>
                <a href={url}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#3B5998" className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg></a>
                {canShowMoreButton && <span className={styles.showMore} onClick={handleShowMoreClick}>მეტის წაკითხვა</span>}
            </div>
        </div>
    </div>
}